import {QuestionObject} from '@/dataObjects/area1personal/QuestionObject.js';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';

const LANGUAGES_LIST = {
	bengali: 'bengali',
	bulgarian: 'bulgarian',
	croatian: 'croatian',
	czech: 'czech',
	danish: 'danish',
	dutch: 'dutch',
	english: 'english',
	estonian: 'estonian',
	finnish: 'finnish',
	french: 'french',
	german: 'german',
	greek: 'greek',
	hindi: 'hindi',
	hungarian: 'hungarian',
	irish: 'irish',
	japanese: 'japanese',
	latvian: 'latvian',
	lithuanian: 'lithuanian',
	maltese: 'maltese',
	polish: 'polish',
	portuguese: 'portuguese',
	romanian: 'romanian',
	russian: 'russian',
	slovak: 'slovak',
	slovenian: 'slovenian',
	spanish: 'spanish',
	swedish: 'swedish',
	turkish: 'turkish',
    other: 'other'
}

//altro genitore
//altro partner non genitore
const areaPersonalFamilyAdult1Page = (module, i) => {
	const page = `${module}.page${i}`

	let questionNumberIf = 0, questionValueIf = '0'
	switch(i) {
		case 4: {
			questionNumberIf = 1
			questionValueIf = ['1'] //altro genitore si
			break
		}
		case 5: {
			questionNumberIf = 2
			questionValueIf = ['1'] //altro partner non genitore si
			break
		}
		default: console.error('areaPersonalFamilyAdult1Page, bad i', i)
	}
	return {
		id: page,
		title: `${page}.title`,
		questions: [
			{
				type: QuestionObject.TYPE_BUTTONS,
				text: `gender`,
				buttons: {
					[QuestionObject.ANSWER_VALUES_GENDER_MALE]: `genderMale`,
					[QuestionObject.ANSWER_VALUES_GENDER_FEMALE]: `genderFemale`,
					[QuestionObject.ANSWER_VALUES_GENDER_NONE]: `iDontSpecify`,
					[QuestionObject.ANSWER_VALUES_GENDER_OTHER]: `other`,
				}
			},
			{
				type: QuestionObject.TYPE_NUMBER,
				text: `age`,
                max : 100,
			},
			{
				type: QuestionObject.TYPE_LARGE_BUTTONS,
				text: `instruction`,
				buttons: {
					1: `instruction1`,
					2: `instruction2`,
					3: `instruction3`,
					4: `instruction4`,
					5: `instruction5`,
					6: `instruction6`,
					7: `other`,
				},
			},
			{
				type: QuestionObject.TYPE_TEXT,
				text: `occupation`,
			},
			{
				type: QuestionObject.TYPE_RADIO_THEN_SELECT,
				text: `doTheySpeakALanguageOtherThanItalian?`,
				buttons: {
					1: `no`,
					2: `ifYesWhich?`,
				},
				options: LANGUAGES_LIST,
			},
		],
		//solo se ho risposto si a questa domanda con questo valore
		if:{[`area1personal_module1_page1_quest${questionNumberIf}`]: questionValueIf},
		hasModalEnd: true,
	}
}
//nonni
//altre persone
const areaPersonalFamilyAdult2Page = (module, i) => {
	const page = `${module}.page${i}`

	let questionNumberIf = 0, questionValueIf = '0'
	switch(i) {
		case 6: {
			questionNumberIf = 4
			questionValueIf = ['1', '2'] //uno o due nonni
			break
		}
		case 7: {
			questionNumberIf = 4
			questionValueIf = ['2'] //due nonni
			break
		}
		case 8: {
			questionNumberIf = 5
			questionValueIf = ['1', '2'] //uno o due altre persone
			break
		}
		case 9: {
			questionNumberIf = 5
			questionValueIf = ['2'] //due altre persone
			break
		}
		default: console.error('areaPersonalFamilyAdult2Page, bad i', i)
	}
	return {
		id: page,
		title: `${page}.title`,
		questions: [
			{
				type: QuestionObject.TYPE_BUTTONS,
				text: `gender`,
				buttons: {
					[QuestionObject.ANSWER_VALUES_GENDER_MALE]: `genderMale`,
					[QuestionObject.ANSWER_VALUES_GENDER_FEMALE]: `genderFemale`,
					[QuestionObject.ANSWER_VALUES_GENDER_NONE]: `iDontSpecify`,
					[QuestionObject.ANSWER_VALUES_GENDER_OTHER]: `other`,
				}
			},
			{
				type: QuestionObject.TYPE_NUMBER,
				text: `age`,
                max : 100,
			},
			{
				type: QuestionObject.TYPE_RADIO_THEN_SELECT,
				text: `doTheySpeakALanguageOtherThanItalian?`,
				buttons: {
					1: `no`,
					2: `ifYesWhich?`,
				},
				options: LANGUAGES_LIST,
			},
		],
		//solo se ho risposto si a questa domanda con questo valore
		if:{[`area1personal_module1_page1_quest${questionNumberIf}`]: questionValueIf},
		hasModalEnd: true,
	}
}
//altri figli
const areaPersonalFamilyOtherChildrenPage = (module, i) => {
	const page = `${module}.page${i}`
	const firstI = 10
	const countI = 8
	//i===10 > ['8', '7', '6', '5', '4', '3', '2', '1']
	//...
	//i===16 > ['8', '7']
	//i===17 > ['8']
	//https://stackoverflow.com/a/33352604/3740246
	const questionValueIf = Array.from({length: firstI + countI - i}, (v, i) => {
		return Math.abs(countI - i).toString()
	})
	return {
		id: page,
		title: `${page}.title`,
		questions: [
			{
				type: QuestionObject.TYPE_BUTTONS,
				text: `gender`,
				buttons: {
					[QuestionObject.ANSWER_VALUES_GENDER_MALE]: `genderMale`,
					[QuestionObject.ANSWER_VALUES_GENDER_FEMALE]: `genderFemale`,
					[QuestionObject.ANSWER_VALUES_GENDER_NONE]: `iDontSpecify`,
					[QuestionObject.ANSWER_VALUES_GENDER_OTHER]: `other`,
				}
			},
			{
				type: QuestionObject.TYPE_NUMBER,
				text: `age`,
                max : 100,
			},
			{
				type: QuestionObject.TYPE_BUTTONS,
				text: `doesHeAttendSchool?`,
				buttons: {
					1: `yes`,
					2: `no`,
				}
			},
		],
		//solo se ho risposto si a questa domanda con questo valore
		if:{area1personal_module1_page1_quest3: questionValueIf},
		hasModalEnd: true,
	}
}

//persone non conviventi che il bimbo frequenta
const areaPersonalChildFriendsPage = (pageId, title, n) => {

	let questionNumberIf = 0,
		questionValueIf = n === 1 ? ['1', '2'] : ['2']

	switch(title) {
		case 'grandparents/': {
			questionNumberIf = '2'
			break
		}
		case 'uncles/': {
			questionNumberIf = '3'
			break
		}
		case 'babySitter': {
			questionNumberIf = '4'
			break
		}
		case 'neighbour': {
			questionNumberIf = '5'
			break
		}
		case 'friendParent': {
			questionNumberIf = '6'
			break
		}
		case 'otherRelative': {
			questionNumberIf = '7'
			break
		}
		default: console.error('areaPersonalChildFriendsPage, bad title', title)
	}
	return {
		id: pageId,
		title: `${title}`,
		questions: [
			{
				type: QuestionObject.TYPE_BUTTONS,
				text: `frequencyOfTimeSpentTogether`,
				buttons: {
					1: `onceAWeek`,
					2: `twiceAWeek`,
					3: `3-4TimesAWeek`,
					4: `everyday`,
				}
			},
			{
				type: QuestionObject.TYPE_RADIO_THEN_SELECT,
				text: `spokenLanguages`,
				buttons: {
					1: `no`,
					2: `ifYesWhich?`,
				},
				options: LANGUAGES_LIST,
			},
		],
		if:{[`area1personal_module5_page1_quest${questionNumberIf}`]: questionValueIf},
		hasModalEnd: true,
	}
}
const childLanguagesPage = (module, i) => {

	let pageNumberIf = 0, questionNumberIf = 0
    const questionVariant = i === 1 ? '_1' : ''
	switch(i) {
		case 1: {
			pageNumberIf = 1 //tu
			questionNumberIf = 5
			break
		}
		case 2: {
			pageNumberIf = 4 //altro genitore
			questionNumberIf = 5
			break
		}
		case 3: {
			pageNumberIf = 5 //partner non genitore
			questionNumberIf = 5
			break
		}
		case 4: {
			pageNumberIf = 8 //persona esterna (altra) 1
			questionNumberIf = 3
			break
		}
		case 5: {
			pageNumberIf = 9 //persona esterna (altra) 2
			questionNumberIf = 3
			break
		}
		default: console.error('childLanguagesPage, bad i', i)
	}
	const page = `${module}.page${i}`
	return {
		id: page,
		title: `${page}.title`,
		questions: [
			{
				type: QuestionObject.TYPE_NUMBER,
				text: `${page}.question1.text`,
			},
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `${page}.question2${questionVariant}.text`,
			},
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `${page}.question3${questionVariant}.text`,
			},
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `${page}.question4.text`,
			},
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `${page}.question5.text`,
			},
		],
		hasModalEnd: true,
		if:{[`area1personal_module2_page${pageNumberIf}_quest${questionNumberIf}`]: ['2']},
	}
}

/**
 * areaPersonal, modules and pages starts with index 1
 *
 * il testo delle proprieta' come 'title', 'text' oppure i bottoni sono le chiavi dei json delle lingue
 * @see /storage/platformConfig/localization/it.json
 *
 * 1 familyElementsList
 * 2 family
 * 3 house
 * 4 childInteractions
 * 5 childFriends
 * 6 childLanguages
 **/
let area, module, page
export const dataArea1personal = {
	id: area = AreaObject.AREA1_PERSONAL,
	title: `${area}.title`,
	modules: [
		//familyElementsList
		{
			id: module = `${area}.module1`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question1.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							}
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question2.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							}
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `${page}.question3.text1`,
							text2: `specifyHowMany`,
							min: 0,
							max: 8,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `${page}.question4.text1`,
							text2: `specifyHowManyGrandparentsSpecial`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `${page}.question5.text1`,
							text2: `specifyHowMany`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question6.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							}
						},
					],
					hasModalEnd: true,
				},
			],
		},
		//family
		{
			id: module = `${area}.module2`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `gender`,
							buttons: {
								[QuestionObject.ANSWER_VALUES_GENDER_MALE]: `genderMale`,
								[QuestionObject.ANSWER_VALUES_GENDER_FEMALE]: `genderFemale`,
								[QuestionObject.ANSWER_VALUES_GENDER_NONE]: `iDontSpecify`,
								[QuestionObject.ANSWER_VALUES_GENDER_OTHER]: `other`,
							}
						},
						{
							type: QuestionObject.TYPE_NUMBER,
							text: `age`,
                            max : 100,
						},
						{
							type: QuestionObject.TYPE_LARGE_BUTTONS,
							text: `instruction`,
							buttons: {
								1: `instruction1`,
								2: `instruction2`,
								3: `instruction3`,
								4: `instruction4`,
								5: `instruction5`,
								6: `instruction6`,
								7: `other`,
							},
						},
						{
							type: QuestionObject.TYPE_TEXT,
							text: `occupation`,
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_SELECT,
							text: `doYouSpeakALanguageOtherThanItalian?`, // THIS MUST NOT BE GENDERED, gender of the child is not yet known!
							buttons: {
								1: `no`,
								2: `ifYesWhich?`,
							},
							options: LANGUAGES_LIST,
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page2`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `gender`,
							buttons: {
								[QuestionObject.ANSWER_VALUES_GENDER_MALE]: `genderMale`,
								[QuestionObject.ANSWER_VALUES_GENDER_FEMALE]: `genderFemale`,
								// [QuestionObject.ANSWER_VALUES_GENDER_NONE]: `iDontSpecify`, // 17/09 remove child "iDontSpecify" to avoid conflict on default string gender
							}
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `ageInMonths`,
							min: 23,
							max: 37,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `andHowManyDays?`,
							min: 0,
							max: 30,
						},
						{
							type: QuestionObject.TYPE_TEXT,
							text: `nationality`,
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page3`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_RADIO_THEN_NUMBER,
							text: `${page}.question1.text`,
							buttons: {
								1: `no`,
								2: `${page}.question1.answer2`,
							},
                            min: 0,
                            max: 50,
						},
						{
							type: QuestionObject.TYPE_NUMBER,
							text: `${page}.question2.text`,
							min: 0,
                            max: 36,
							disabledIf: {area1personal_module2_page3_quest1: ['1']},
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question3.text`,
							buttons: {
								1: `${page}.question3.answer1`,
								2: `${page}.question3.answer2`,
							},
							disabledIf: {area1personal_module2_page3_quest1: ['1']},
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_TEXTAREA,
							text: `${page}.question4.text`,
							buttons: {
								1: `${page}.question4.answer1`,
								2: `${page}.question4.answer2`,
								3: `${page}.question4.answer3`,
								4: `${page}.question4.answer4`,
								5: `otherSpecify`,
							},
							disabledIf: {area1personal_module2_page3_quest1: ['1']},
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_SELECT,
							text: `${page}.question5.text`,
							buttons: {
								1: `no`,
								2: `${page}.question5.answer2`,
							},
							options: LANGUAGES_LIST,
							disabledIf: {area1personal_module2_page3_quest1: ['1']},
						},
					],
					//nido d-infanzia >>> 1
					hasModalEnd: true,
				},
				areaPersonalFamilyAdult1Page(module, 4), //altro genitore
				areaPersonalFamilyAdult1Page(module, 5), //altro partner non genitore
				areaPersonalFamilyAdult2Page(module, 6), //nonni
				areaPersonalFamilyAdult2Page(module, 7), //nonni
				areaPersonalFamilyAdult2Page(module, 8), //altre persone
				areaPersonalFamilyAdult2Page(module, 9), //altre persone
				areaPersonalFamilyOtherChildrenPage (module, 10), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 11), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 12), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 13), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 14), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 15), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 16), //altri figli
				areaPersonalFamilyOtherChildrenPage (module, 17), //altri figli
				{
					id: page = `${module}.page18`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_TEXT,
							text: `name`,
						},
						{
							type: QuestionObject.TYPE_TEXT,
							text: `species`,
						},
					],
					if:{area1personal_module1_page1_quest6: ['1']},
					hasModalEnd: true,
				}
			],
		},
		//house
		{
			id: module = `${area}.module3`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_NUMBER,
							text: `${page}.question2.text`,
                            max: 10,
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question3.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							}
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question4.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							}
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question5.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							}
						},
					],
				},
			],
		},
		//childInteractions
		{
			id: module = `${area}.module4`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_RADIO_THEN_NUMBER,
							text: `${page}.question1.text`,
							buttons: {
								1: `nobody`,
								2: `1-20`,
								3: `21-40`,
								4: `41-60`,
								5: `61-80`,
								6: `ifMoreAboutHowMany?`,
							}
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_NUMBER,
							text: `${page}.question2.text`,
							buttons: {
								1: `nobody`,
								2: `1-20`,
								3: `21-40`,
								4: `41-60`,
								5: `61-80`,
								6: `ifMoreAboutHowMany?`,
							}
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_NUMBER,
							text: `${page}.question3.text`,
							buttons: {
								1: `nobody`,
								2: `1`,
								3: `2`,
								4: `3`,
								5: `4`,
								6: `ifMoreThan4AboutHowMany`,
							}
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_NUMBER,
							text: `${page}.question4.text`,
							buttons: {
								1: `nobody`,
								2: `1`,
								3: `2`,
								4: `3`,
								5: `4`,
								6: `ifMoreThan4AboutHowMany`,
							}
						},
						{
							type: QuestionObject.TYPE_RADIO,
							text: `${page}.question5.text`,
							buttons: {
								1: `nobody`,
								2: `1-20`,
								3: `21-40`,
								4: `41-60`,
								5: `61-80`,
                                6: `subscription`,
							}
						},
						{
							type: QuestionObject.TYPE_RADIO,
							text: `${page}.question6.text`,
							buttons: {
								1: `nobody`,
								2: `1-20`,
								3: `21-40`,
								4: `41-60`,
								5: `61-80`,
								6: `subscription`,
							}
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page2`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_DRAG_AND_DROP,
							elements: `${page}.question1.text`,
							values: QuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_MUSICAL_INSTRUMENTS_CHILDREN,
                            description: `dropTheInstrumentsInTheBox`
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page3`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_DRAG_AND_DROP,
							elements: `${page}.question1.text`,
							values: QuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_MUSICAL_INSTRUMENTS_ADULTS,
                            description: `dropTheInstrumentsInTheBox`
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page4`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_DRAG_AND_DROP,
							elements: `${page}.question1.text`,
							values: QuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_ELECTRONIC_DEVICES,
                            description: `dropTheDevicesInTheBox`
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page5`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_DRAG_AND_DROP,
							elements: `${page}.question1.text`,
							values: QuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_GAME_TYPES,
                            description: `dropTheToysInTheBox`
						},
					],
				},
				{
					id: page = `${module}.page6`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_DRAG_AND_DROP,
							elements: `${page}.question1.text`,
							values: QuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_GAME_TYPES,
                            description: `dropTheToysInTheBox`,
							limit: 5,
						},
					],
					hasModalEnd: true,
					if: {
						//quando hai comprato il compressore semiprofessionale e lo usi anche per spolverare il sellino della bici
						[`${area}_module4_page5_quest1`]: [`gameTypes${QuestionObject.ANSWER_SEPARATOR_KEYVALUE}${QuestionObject.ANSWER_VALUE_NONE}`],
						operator: '!=='
					},
				},
			],
		},
		//childFriends
		{
			id: module = `${area}.module5`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `${page}.question1.text`,
							buttons: {
								1: `yes`,
								2: `no`,
							},
							if:{area1personal_module1_page1_quest1: ['2']} //altro genitore vive a casa? No, quindi chiedo!
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `grandparents`,
							text2: `specifyHowManyGrandparentsSpecial`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `uncles`,
							text2: `specifyHowMany`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `babySitter`,
							text2: `specifyHowMany`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `neighbour`,
							text2: `specifyHowMany`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `friendParent`,
							text2: `specifyHowMany`,
							min: 0,
							max: 2,
						},
						{
							type: QuestionObject.TYPE_INCREASE_DECREASE,
							text1: `otherRelative`,
							text2: `specifyHowMany`,
							min: 0,
							max: 2,
						},
					],
					hasModalEnd: true,
				},
				{
					id: page = `${module}.page2`,
					title: `${page}.title`,
					questions: [
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `instruction`,
							buttons: {
								1: `instruction1`,
								2: `instruction2`,
								3: `instruction3`,
								4: `instruction4`,
								5: `instruction5`,
								6: `instruction6`,
								7: `other`,
							},
						},
						{
							type: QuestionObject.TYPE_BUTTONS,
							text: `frequencyOfTimeSpentTogether`,
							buttons: {
								1: `onceAWeek`,
								2: `twiceAWeek`,
								3: `3-4TimesAWeek`,
								4: `everyday`,
							},
						},
						{
							type: QuestionObject.TYPE_RADIO_THEN_SELECT,
							text: `spokenLanguages`,
							buttons: {
								1: `no`,
								2: `ifYesWhich?`,
							},
							options: LANGUAGES_LIST,
						},
					],
					if:{area1personal_module5_page1_quest1: ['1']} //altro genitore CHE NON VIVE A CASA (e quindi ho chiesto anche qui in modulo 5) vede spesso il bambino? SI
				},
				areaPersonalChildFriendsPage(`${module}.page3` , 'grandparents/', 1),
				areaPersonalChildFriendsPage(`${module}.page4` , 'grandparents/', 2),
				areaPersonalChildFriendsPage(`${module}.page5` , 'uncles/', 1),
				areaPersonalChildFriendsPage(`${module}.page6` , 'uncles/', 2),
				areaPersonalChildFriendsPage(`${module}.page7` , 'babySitter', 1),
				areaPersonalChildFriendsPage(`${module}.page8` , 'babySitter', 2),
				areaPersonalChildFriendsPage(`${module}.page9` , 'neighbour', 1),
				areaPersonalChildFriendsPage(`${module}.page10`, 'neighbour', 2),
				areaPersonalChildFriendsPage(`${module}.page11`, 'friendParent', 1),
				areaPersonalChildFriendsPage(`${module}.page12`, 'friendParent', 2),
				areaPersonalChildFriendsPage(`${module}.page13`, 'otherRelative', 1),
				areaPersonalChildFriendsPage(`${module}.page14`, 'otherRelative', 2),
			],
		},
		//childLanguages
		{
			id: module = `${area}.module6`,
			title: `${module}.title`,
			pages: [
				childLanguagesPage(module, 1),
				childLanguagesPage(module, 2),
				childLanguagesPage(module, 3),
				childLanguagesPage(module, 4),
				childLanguagesPage(module, 5),
			],
		},
	]
}
